import "./BtnStyle.scss";
import React, { memo } from "react";
import { Button } from "reactstrap";

const FillBtn = (props) => {
  const { text, disabled, className, handleOnClick, type = "button" } = props;

  return (
    <Button
    type={type}
    disabled={disabled}
    onClick={handleOnClick}
    className={`fillBtn fw-bold buttonBoxShadow ${className}`}
    >
      {text}
    </Button>
  );
};

export default memo(FillBtn);
