import moment from "moment/moment";

const filterSignUpFields = (user) => {
  if (user === null) {
    return [
      "zip",
      "dob",
      "city",
      "email",
      "state",
      "gender",
      "password",
      "about_me",
      "last_name",
      "first_name",
      "socialLogin",
      "confirm_password",
      "term_and_condition",
    ];
  }
  return [];
};

const setInitializeSignUpValues = (signUpInitialValues, user) => {
  return {
    ...signUpInitialValues,
    dob: user?.dob || "",
    zip: user?.zip || "",
    city: user?.city || "",
    state: user?.state || "",
    email: user?.email || "",
    gender: user?.gender || "",
    about_me: user?.about_me || "",
    last_name: user?.last_name || "",
    first_name: user?.first_name || "",
  };
};

// function convertUTCToLocal(meetingTimeUTC) {
//   if (meetingTimeUTC) {
//     const parsedTime = moment.utc(meetingTimeUTC);
//     if (!parsedTime.isValid()) {
//       throw new Error("Invalid date format provided");
//     }
//     return parsedTime.local();
//   }
// }

// ===============================
const convertUTCToLocal = (utcDateString) => {
  if (!utcDateString) return null;

  try {
    const utcDate = new Date(utcDateString + "Z");

    if (isNaN(utcDate.getTime())) {
      console.error("Invalid date:", utcDateString);
      return "Invalid date";
    }

    const localDateString = utcDate.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    const localTimeString = utcDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    const timeZoneOffset = -utcDate.getTimezoneOffset();
    const offsetHours = Math.floor(timeZoneOffset / 60);
    const offsetMinutes = timeZoneOffset % 60;
    const offsetSign = offsetHours >= 0 ? "+" : "-";
    const formattedOffset = `${offsetSign}${String(
      Math.abs(offsetHours)
    ).padStart(2, "0")}${String(offsetMinutes).padStart(2, "0")}`;

    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return `${localDateString} ${localTimeString} GMT${formattedOffset} (${timeZoneName})`;
  } catch (error) {
    console.error("Error converting UTC to local time:", error);
    return "Error converting date";
  }
};

// const convertUTCToLocal = (utcDateString) => {
//   if (!utcDateString) return null;

//   try {
//     const parsedTime = moment.utc(utcDateString);
//     if (!parsedTime.isValid()) {
//       console.error("Invalid date:", utcDateString);
//       return "Invalid date";
//     }

//     // Convert to local time
//     const localTime = parsedTime.local();

//     // Format date and time
//     const localDateString = localTime.format("ddd, MMM D, YYYY");
//     const localTimeString = localTime.format("HH:mm:ss");

//     // Get the user's timezone
//     const timeZoneName = moment.tz.guess();

//     // Format the timezone offset (e.g., +02:00 or -05:00)
//     const timeZoneOffset = localTime.format("Z");

//     return `${localDateString} ${localTimeString} GMT${timeZoneOffset} (${timeZoneName})`;
//   } catch (error) {
//     console.error("Error converting UTC to local time:", error);
//     return "Error converting date";
//   }
// };

// =====================================

function formatUserInputDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.error("Invalid date value:", dateString);
    return "Invalid Date";
  }

  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  try {
    let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    if (!/AM|PM/.test(formattedDate)) {
      const hours = date.getHours();
      const ampm = hours >= 12 ? "PM" : "AM";
      formattedDate = `${formattedDate} ${ampm}`;
    }

    return formattedDate;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid Date";
  }
}

const encodeURL = (url) => {
  return url.replace(/ /g, "%20").replace(/\(/g, "%28").replace(/\)/g, "%29");
};

const extractTime = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "";
  const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.format(date);
};

export default {
  encodeURL,
  extractTime,
  convertUTCToLocal,
  filterSignUpFields,
  formatUserInputDate,
  setInitializeSignUpValues,
};
