import { createSlice } from "@reduxjs/toolkit";
import { getSingleSurvey, getAllSurvey, storeSurvey } from "./surveyApi";

export const surveySlice = createSlice({
  name: "survey",
  initialState: {
    loading: "idle",
    error: null,
    success: null,
    email: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSurvey.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllSurvey.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(getAllSurvey.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.error;
      })
      .addCase(storeSurvey.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(storeSurvey.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(storeSurvey.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload?.error;
      })
      .addCase(getSingleSurvey.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getSingleSurvey.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(getSingleSurvey.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.error;
      });
  },
});

export default surveySlice.reducer;
