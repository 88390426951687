import React from "react";

const Tibit = (props) => {
  const { profileImg, profileName, lastSeen, meetingTitle } = props;
  return (
    <div className="d-flex align-items-center justify-content-between mb-2">
      <div className="d-flex align-items-center justify-content-start gap-2 ">
        <div
          className="rounded-circle shadow-sm bgProperties"
          style={{
            backgroundImage: `url(${profileImg})`,
            width: "45px",
            height: "45px",
          }}
        ></div>
        <div className="flex justify-between ">
          <h6
            className="fw-bold mb-0"
            style={{
              width: "200px",
              WebkitLineClamp: 1,
              overflow: "hidden",
              display: "-webkit-box",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
            }}
          >
            {meetingTitle}
          </h6>
          <span
            className="small"
            style={{
              width: "200px",
              WebkitLineClamp: 1,
              overflow: "hidden",
              display: "-webkit-box",
              textOverflow: "ellipsis",
              WebkitBoxOrient: "vertical",
            }}
          >
            with {profileName}
          </span>
        </div>
      </div>
      <span className="small" style={{ width: "200px" }}>
        {lastSeen}
      </span>
    </div>
  );
};

export default Tibit;
