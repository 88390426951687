import Tibit from "../TtibitsCard";
import Functions from "../../utils/functions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_MEETINGS_PAGINATED } from "../../utils/constants";
import Images from "../../HelperMethods/Constants/ImgConstants";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { getMeeting } from "../../Redux/features/meetings/meetingApi";

const formatTimeDifference = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const years = Math.floor(days / 365);

  const remainingDays = days % 365;
  const remainingHours = hours % 24;
  const remainingMinutes = minutes % 60;
  const remainingSecond = seconds % 60;

  let timeString = "";
  if (years > 0) {
    timeString = `${years} Y`;
  } else if (remainingDays > 0) {
    timeString = `${remainingDays} Days`;
  } else if (remainingHours > 0 || remainingMinutes > 0) {
    if (remainingHours > 0) timeString = `${remainingHours}h `;
    if (remainingMinutes > 0) timeString += `${remainingMinutes}m`;
  } else if (remainingSecond > 0) {
    timeString = "streaming";
  }

  return timeString !== "" && "In " + timeString;
};

const UpComingTibits = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [sortedTibits, setSortedTibits] = useState([]);
  const { fcmToken } = useSelector((state) => state.user);
  useEffect(() => {
    const fetchMeetings = async () => {
      const data = {
        apiEndpoint:
          process.env.REACT_APP_BACKEND_BASE_URL + GET_MEETINGS_PAGINATED,
        requestData: JSON.stringify({ fcm_web: fcmToken }),
      };
      const res = await dispatch(getMeeting(data));
      const meetings = res.payload?.data?.data || [];

      const currentDate = new Date();
      const filteredMeetings = meetings.filter(
        (item) =>
          item.guest !== null &&
          new Date(Functions.convertUTCToLocal(item.meeting_datetime)) >
            currentDate &&
          Functions.convertUTCToLocal(item.meeting_datetime) !== "" &&
          Functions.convertUTCToLocal(item.meeting_datetime) !== null
      );
      const transformedData = filteredMeetings
        .map((item) => {
          const meetingStart = new Date(
            Functions.convertUTCToLocal(item.meeting_datetime)
          );
          const timeDifference = meetingStart - currentDate;

          const profileImg =
            user.user.profile_picture_path ===
            item?.created_by?.profile_picture_path
              ? item.guest.profile_picture_path || Images.USER_DUMMY_IMG
              : item?.created_by?.profile_picture_path || Images.USER_DUMMY_IMG;

          const ProfileName =
            user.user.name === item.created_by?.name
              ? item.guest?.name
              : item.created_by?.name;

          console.log(profileImg, "--------------------profileImg");
          return {
            ...item,
            timeDifference,
            lastSeen: formatTimeDifference(timeDifference),
            profileName: ProfileName,
            meetingTitle: item?.title,
            profileImg: profileImg,
          };
        })
        .sort((a, b) => a.timeDifference - b.timeDifference)
        .slice(0, 10);

      setSortedTibits(transformedData);
    };

    fetchMeetings();
  }, [dispatch]);
  console.log(sortedTibits, "-----------------------------------sortedTibits");
  return (
    <>
      {!user?.isGuest && (
        <Container className="my-3 ">
          <Card className="orangeBorder boxShadowDark bg-transparent rounded-4 pb-2">
            <CardHeader className="border-0 bg-transparent">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-2 fw-bold">Upcoming Tibits</h5>
                {/* <FillBtn
              className="rounded-2 text-white p-1"
              text={<HiPlusSm className="fs-3" />}
            /> */}
              </div>
            </CardHeader>
            <CardBody className="rounded-4 px-2 py-1">
              {sortedTibits && sortedTibits.length !== 0 ? (
                sortedTibits.map((tibit, index) => (
                  <Tibit
                    key={index}
                    lastSeen={tibit?.lastSeen}
                    profileImg={tibit?.profileImg}
                    profileName={tibit?.profileName}
                    meetingTitle={tibit?.meetingTitle}
                  />
                ))
              ) : (
                <p className="text-center textOrange fw-bold">No Tibit found</p>
              )}
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
};

export default UpComingTibits;
