import axiosInstance from "../../interceptor";
import Toaster from "../../../Shared/Toaster";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { SUCCESS_CODES } from "../../../utils/constants";

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async ({ apiEndpoint, requestData, email }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(apiEndpoint, requestData);
      if (SUCCESS_CODES.includes(response.status)) {
        Toaster.success(response?.data?.message);
      }
      return email;
    } catch (error) {
      Toaster.error(error?.response?.data?.Message);
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const newPassword = createAsyncThunk(
  "newPassword",
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(apiEndpoint, requestData);
      if (SUCCESS_CODES.includes(response?.status)) {
        Toaster.success(response?.message || "Password updated successfully");
      }
      return response.data;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      Toaster.error(errorMessage);
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);
