import { createSlice } from "@reduxjs/toolkit";
import {
  getEpisodes,
  editEpisodeVSQ,
  getRandomVideosStartersQuestionForEdit,
} from "./episodesApi";

export const episodesSlice = createSlice({
  name: "episodes",
  initialState: {
    loading: "idle",
    error: null,
    success: null,
    email: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEpisodes.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getEpisodes.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(getEpisodes.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload?.error;
      })

      .addCase(getRandomVideosStartersQuestionForEdit.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(
        getRandomVideosStartersQuestionForEdit.fulfilled,
        (state, action) => {
          state.success = true;
          state.loading = "succeeded";
        }
      )
      .addCase(
        getRandomVideosStartersQuestionForEdit.rejected,
        (state, action) => {
          state.loading = "failed";
          state.error = action?.payload?.error;
        }
      )
      .addCase(editEpisodeVSQ.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(editEpisodeVSQ.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(editEpisodeVSQ.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.error;
      });
  },
});

export default episodesSlice.reducer;
