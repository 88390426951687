import { thunk } from "redux-thunk";
import userSlice from "./features/User/userSlice.js";
import { persistStore, persistReducer } from "redux-persist";
import episodesSlice from "./features/episodes/episodesSlice.js";
import createMeetingSlice from "./features/meetings/meetingSlice.js";
import forgotPasswordSlice from "./features/ForgotPassword/forgotPasswordSlice.js";
import {
  configureStore,
  combineReducers,
  applyMiddleware,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { surveySlice } from "./features/survey/surveySlice.js";

const persistConfig = {
  storage,
  version: 1,
  key: "root",
};
const rootReducer = combineReducers({
  user: userSlice,
  episodes: episodesSlice,

  meetings: createMeetingSlice,
  forgotPassword: forgotPasswordSlice,
  survey: surveySlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore(
  { reducer: persistedReducer },
  applyMiddleware(thunk)
);

export const persistor = persistStore(store);
