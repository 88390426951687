import React, { useState } from "react";
import { Button } from "reactstrap";
import "./BtnStyle.scss";

const OutlineBtn = (props) => {
  const { className, text, handleOnClick, disabled = false, style } = props;

  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUpOrLeave = () => {
    setIsPressed(false);
  };

  return (
    <Button
      style={style}
      disabled={disabled}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUpOrLeave}
      onMouseLeave={handleMouseUpOrLeave}
      onClick={handleOnClick}
      className={`OutlineBtn ${isPressed ? "pressed" : ""} ${className}`}
    >
      {text}
    </Button>
  );
};

export default React.memo(OutlineBtn);
