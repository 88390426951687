import "./App.css";
import React, { Suspense } from "react";
import routes from "./Routes/AllRoutes";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import "../src/Assets/Scss/globalStyleSheet.scss";
import { PublicRoute } from "./Routes/PublicRoutes";
import { PrivateRoute } from "./Routes/PrivateRoutes";
import GeneralLayout from "./Pages/Layout/GeneralLayout";
import LoadingScreen from "./HelperMethods/LoadingScreen";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
function App() {
  const { user } = useSelector((state) => state.user);
  console.log({ user });
  return (
    <React.Fragment>
      <GoogleOAuthProvider clientId="618878695311-1ccm4aelncfennea6vt7pu73cdr2b8jp.apps.googleusercontent.com">
        <Toaster />
        <Router>
          <Routes>
            {routes.map((route, index) => {
              const Component = route.component;
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={
                    <>
                      <GeneralLayout
                        isPublic={route.isPublic}
                        isGuest={route.isGuest}
                        isPrivate={route.isPrivate}
                        isAuth={route.isAuth}
                      >
                        <Suspense fallback={<LoadingScreen />}>
                          {route.path == "/meeting" ||
                          route.path == "/scheduleTibit/:meetingId" ? (
                            user ? (
                              <PrivateRoute
                                props={route}
                                role={route?.role}
                                Component={Component}
                              />
                            ) : (
                              <PublicRoute
                                props={route}
                                role={route?.role}
                                Component={Component}
                              />
                            )
                          ) : !route.isPublic ? (
                            <PrivateRoute
                              props={route}
                              role={route?.role}
                              Component={Component}
                            />
                          ) : (
                            <PublicRoute
                              props={route}
                              role={route?.role}
                              Component={Component}
                            />
                          )}
                        </Suspense>
                      </GeneralLayout>
                    </>
                  }
                />
              );
            })}
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </React.Fragment>
  );
}

export default App;
