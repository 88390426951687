import Styles from "./style.module.scss";
import { IoLogOut } from "react-icons/io5";
import React, { memo, useState } from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Images from "../../HelperMethods/Constants/ImgConstants";
import { customLogout } from "../../Redux/features/User/userSlice";
import { Button, ListGroup, ListGroupItem, Tooltip } from "reactstrap";
import { AiFillExclamationCircle, AiOutlineHome } from "react-icons/ai";
import { LuCalendar, LuShieldAlert, LuUser, LuVideo } from "react-icons/lu";
import { TbCalendarShare } from "react-icons/tb";

const LeftNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  // State variables for tooltips
  const [tooltipOpen, setTooltipOpen] = useState({
    homeTooltip: false,
    meetingTooltip: false,
    profileTooltip: false,
    // calendarTooltip: false,
  });

  const toggleTooltip = (name) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleLogoutClick = () => {
    dispatch(customLogout());
    navigate("/");
  };

  return (
    <React.Fragment>
      <div
        className={`d-md-flex flex-md-column justify-content-between w-100 d-none ${Styles.mainMenu}`}
      >
        <div className={`w-100 py-3`}>
          <ListGroup
            className={`d-flex justify-content-center align-items-center gap-3 ${Styles.ListGroup}`}
          >
            <ListGroupItem className={` ${Styles.ListGroupItem}`}>
              <Link to="/dashboard">
                <Button className="bg-white boxShadowDark">
                  <img
                    style={{ width: "120%" }}
                    src={Images.SHORT_LOGO_IMG}
                    alt=""
                  />
                </Button>
              </Link>
            </ListGroupItem>
          </ListGroup>
        </div>
        <div
          className={`w-100 h-50 d-md-flex justify-content-center align-items-center d-none`}
        >
          <ListGroup
            className={`d-flex justify-content-center align-items-center gap-3 ${Styles.ListGroup}`}
          >
            <ListGroupItem className={`${Styles.ListGroupItem}`}>
              <Link to="/dashboard">
                <Button id="homeTooltip">
                  <AiOutlineHome />
                </Button>
              </Link>
              <Tooltip
                placement="top"
                target="homeTooltip"
                isOpen={tooltipOpen.homeTooltip}
                toggle={() => toggleTooltip("homeTooltip")}
              >
                Home
              </Tooltip>
            </ListGroupItem>
            <ListGroupItem className={` ${Styles.ListGroupItem}`}>
              <Link to="/profile">
                <Button id="profileTooltip">
                  <LuUser />
                </Button>
              </Link>
              <Tooltip
                placement="top"
                target="profileTooltip"
                isOpen={tooltipOpen.profileTooltip}
                toggle={() => toggleTooltip("profileTooltip")}
              >
                Profile
              </Tooltip>
            </ListGroupItem>
            {/* <ListGroupItem className={` ${Styles.ListGroupItem}`}>
              <Link to="/tibitCalendar">
                <Button id="calendarTooltip">
                  <LuCalendar />
                </Button>
              </Link>
              <Tooltip
                placement="top"
                target="calendarTooltip"
                isOpen={tooltipOpen.calendarTooltip}
                toggle={() => toggleTooltip("calendarTooltip")}
              >
                Calendar
              </Tooltip>
            </ListGroupItem> */}
            <ListGroupItem className={` ${Styles.ListGroupItem}`}>
              <Link to="/meeting">
                <Button id="meetingTooltip">
                  <LuVideo />
                </Button>{" "}
              </Link>
              <Tooltip
                placement="top"
                target="meetingTooltip"
                isOpen={tooltipOpen.meetingTooltip}
                toggle={() => toggleTooltip("meetingTooltip")}
              >
                New Tibit
              </Tooltip>
            </ListGroupItem>
            <ListGroupItem className={`${Styles.ListGroupItem} d-lg-none d-block`}>
              <Link to="/upcomingTibits">
                <Button>
                  <TbCalendarShare />
                </Button>
              </Link>
            </ListGroupItem>
            {/* <ListGroupItem className={`${Styles.ListGroupItem}`}>
              <Button id="alertTooltip">
                <LuShieldAlert />
              </Button>
              <Tooltip
                placement="top"
                target="alertTooltip"
                isOpen={tooltipOpen.alertTooltip}
                toggle={() => toggleTooltip("alertTooltip")}
              >
                Alert
              </Tooltip>
            </ListGroupItem> */}
            {/* <ListGroupItem className={` ${Styles.ListGroupItem}`}>
              <Button id="addressTooltip">
                <FaRegAddressCard />
              </Button>
              <Tooltip
                placement="top"
                target="addressTooltip"
                isOpen={tooltipOpen.addressTooltip}
                toggle={() => toggleTooltip("addressTooltip")}
              >
                Address
              </Tooltip>
            </ListGroupItem> */}
            {/* <ListGroupItem className={` ${Styles.ListGroupItem}`}>
              <Button id="exclamationTooltip">
                <AiFillExclamationCircle />
              </Button>
              <Tooltip
                placement="top"
                target="exclamationTooltip"
                isOpen={tooltipOpen.exclamationTooltip}
                toggle={() => toggleTooltip("exclamationTooltip")}
              >
                Exclamation
              </Tooltip>
            </ListGroupItem> */}
          </ListGroup>
        </div>
        <div className={`w-100 `}>
          <ListGroup
            className={`d-flex justify-content-center align-items-center gap-3 ${Styles.ListGroup}`}
          >
            <ListGroupItem className={` ${Styles.ListGroupItem} mb-3`}>
              <Button id="logoutTooltip" onClick={handleLogoutClick}>
                <IoLogOut style={{ transform: "rotate(180deg)" }} />
              </Button>
              <Tooltip
                placement="top"
                target="logoutTooltip"
                isOpen={tooltipOpen.logoutTooltip}
                toggle={() => toggleTooltip("logoutTooltip")}
              >
                Logout
              </Tooltip>
            </ListGroupItem>
          </ListGroup>
        </div>
      </div>
      <div
        className={`fixed-bottom d-md-none d-block overflow-scroll ${Styles.mobileFooter}`}
      >
        <ListGroup
          className={`align-items-center gap-4 ${Styles.ListGroupMobile}`}
        >
          <ListGroupItem className={`${Styles.ListGroupItem}`}>
            <Link to="/dashboard">
              <Button>
                <AiOutlineHome />
              </Button>
            </Link>
          </ListGroupItem>
          {/* <ListGroupItem className={`${Styles.ListGroupItem}`}>
            <Link to="/profile">
              <Button>
                <LuCalendar />
              </Button>
            </Link>
          </ListGroupItem> */}
          <ListGroupItem className={`${Styles.ListGroupItem}`}>
            <Link to="/meeting">
              <Button>
                <LuVideo />
              </Button>
            </Link>
          </ListGroupItem>
          <ListGroupItem className={`${Styles.ListGroupItem}`}>
            <Link to="/profile">
              <Button>
                <LuUser />
              </Button>
            </Link>
          </ListGroupItem>
          <ListGroupItem className={`${Styles.ListGroupItem}`}>
            <Link to="/upcomingTibits">
              <Button>
                <TbCalendarShare />
              </Button>
            </Link>
          </ListGroupItem>
          <ListGroupItem className={`${Styles.ListGroupItem}`}>
            <Link to="/profile">
              <Button id="logoutTooltip" onClick={handleLogoutClick}>
                <IoLogOut style={{ transform: "rotate(180deg)" }} />
              </Button>
            </Link>
          </ListGroupItem>
        </ListGroup>
      </div>
    </React.Fragment>
  );
};

export default memo(LeftNav);
