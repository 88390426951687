// interceptor.js
import axios from "axios";
import Toaster from "../Shared/Toaster";
import { customLogout } from "./features/User/userSlice";

const axiosInstance = axios.create({
  // Ensure this URL is correctly set up in your environment variables
  // baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  baseURL: "",

  // ImageBaseURL: process.env.REACT_APP_BACKEND_BASE_URL_FOR_IMAGE,
});

let store;

const initializeAxiosInterceptors = async () => {
  if (!store) {
    const storeModule = await import("./configureStore");
    store = storeModule.store;
  }

  axiosInstance.interceptors.request.use(
    (config) => {
      const hasFiles = config.data instanceof FormData;

      if (hasFiles) {
        config.headers["Content-Type"] = "multipart/form-data";
        config.headers["maxBodyLength"] = "Infinity";
      } else {
        config.headers["Content-Type"] = "application/json";
        config.headers["Accept"] = "application/json";
      }

      const user = store.getState().user?.user;
      const token = store.getState().user?.token;

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;
      if (response && response.status === 401) {
        store.dispatch(customLogout());
        Toaster.error(response?.data?.error?.detail || 'Unauthorized');
      }
      return Promise.reject(error);
    }
  );
};

initializeAxiosInterceptors();

export default axiosInstance;
