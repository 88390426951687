import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  signUp,
  resendOtp,
  verifyOtp,
  editProfile,
  deleteAccount,
  changePassword,
  getUserProfile,
  authChangePassword,
  loggedInData,
} from "./userApi";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    email: "",
    user: null,
    token: null,
    error: null,
    isGuest: false,
    fcmToken: null,
    unReadCount: 0,
    loading: "idle",
    notifications: [],
    shownAppModal: false,
    notificationCount: 0,
    configurations: null,
  },
  reducers: {
    setGuest: (state, action) => {
      state.isGuest = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    customLogout: (state) => {
      state.user = null;
      state.configurations = null;
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setConfigurations: (state, action) => {
      state.configurations = action.payload;
    },
    updateConfigurations: (state, action) => {
      state.configurations = { ...state.configurations, ...action.payload };
    },
    setShownAppModal: (state, action) => {
      state.shownAppModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.configurations = action.payload.data.configurations;
        state.isGuest = false;
        state.loading = "succeeded";
        state.user = action.payload.data.user;
        state.token = action.payload.data.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload ? action.payload.error : null;
      })
      .addCase(loggedInData.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(loggedInData.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(loggedInData.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.error;
      })

      .addCase(verifyOtp.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.configurations = action.payload.data.configurations;
        state.isGuest = false;
        state.loading = "succeeded";
        state.user = action.payload.data.user;
        state.token = action.payload.data.token;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload ? action.payload.error : null;
        state.error = action.payload.error;
      })

      .addCase(resendOtp.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(resendOtp.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(resendOtp.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.error;
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(authChangePassword.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(authChangePassword.fulfilled, (state) => {
        state.loading = "succeeded";
      })
      .addCase(authChangePassword.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.email = action.payload;
      })
      .addCase(signUp.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(deleteAccount.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.user = null;
        state.configurations = null;
        state.notifications = [];
        state.loading = "succeeded";
      })
      .addCase(deleteAccount.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(editProfile.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        // state.user = action.payload.data;
        state.user = {
          ...action.payload.data,
          // tokens: state.user.tokens,
          id: state.user.id,
        };
        // state.configurations = {
        //   ...state.configurations,
        //   ...action.payload.data.configurations,
        // };
        state.loading = "succeeded";
      })
      .addCase(editProfile.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(getUserProfile.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.user = { ...action.payload.data?.[0], tokens: state.user.tokens };
        state.configurations =
          action.payload.data?.[0]?.configurations || state.configurations;
        state.loading = "succeeded";
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.loading = "failed";
      });
  },
});

export const {
  setGuest,
  setEmail,
  setFcmToken,
  customLogout,
  setShownAppModal,
  setConfigurations,
  updateConfigurations,
} = userSlice.actions;

export default userSlice.reducer;
