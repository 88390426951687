import ModalPopUp from "../ModalPopUp";
import Styles from "./style.module.scss";
import { IoLogOut } from "react-icons/io5";
import { LuKeyRound } from "react-icons/lu";
import OutlineBtn from "../Buttons/OutlineBtn";
import { GoBell, GoGear } from "react-icons/go";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import Images from "../../HelperMethods/Constants/ImgConstants";
import { customLogout } from "../../Redux/features/User/userSlice";
import NewPasswordForm from "../../Pages/Auth/NewPassword/NewPasswordForm";
// import { AUTH_CHANGE_PASSWORD_URL } from "../../utils/constants";
// import { authChangePassword } from "../../Redux/features/User/userApi";

const ProfileCard = () => {
  const popupRef = useRef(null);
  const dispatch = useDispatch();

  //  Popup states
  const [settingPopup, setSettingPopup] = useState(false);
  const [ChangePasswordPopup, setChangePasswordPopup] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState();
  const user = useSelector((state) => state.user.user) || {};

  // console.log("User data _+++++++++++++++++++++++++++++++++++++++++++" , user)

  // const backgroundImage = user.profile_picture_path
  //   ? `url(${user.profile_picture_path})`
  //   : `url(${Images.USER_DUMMY_IMG})`;
  useEffect(() => {
    if (user) {
      const imagePath =user.profile_picture_path&& `${
        user.profile_picture_path
      }?t=${new Date().getTime()}`;
      setBackgroundImage(imagePath);
    }
  }, [user]);
  const handleLogoutClick = () => {
    dispatch(customLogout());
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSettingPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
console.log("backkkkkkkk",backgroundImage)
  return (
    <div
      className={`py-3 px-2 d-flex align-items-center justify-content-between inner-shadow bg-transparent ${Styles.RightBar}`}
    >
      <div className="d-flex align-items-center">
        {/* <div
          className={`rounded-circle bgProperties bgOrange me-2 ${Styles.profileImage}`}
          style={{
            backgroundImage: backgroundImage,
          }}
        ></div> */}
        <div
          className={`rounded-circle bgProperties bgOrange me-2 ${Styles.profileImage}`}
          style={{
            overflow: "hidden", // Ensure the image fits within the rounded div
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={backgroundImage ?backgroundImage: Images?.USER_DUMMY_IMG}
            alt="Profile"
            style={{
              width: "100%", // Make the image fill the div's width
              height: "100%", // Make the image fill the div's height
              objectFit: "cover", // Ensure the image covers the div without distortion
            }}
          />
        </div>

        <div>
          <h6 className="fw-bold">{user.name}</h6>
          <p className="small">{user.email}</p>
        </div>
      </div>
      <div className="position-relative">
        <div>
          {/* <Link to="/profile"> */}
          <OutlineBtn
            handleOnClick={() => setSettingPopup(!settingPopup)}
            className="rounded-3 me-2"
            text={<GoGear className="fs-5 textDarkBlue" />}
          />
          {/* </Link>/ */}
          {/* <OutlineBtn
            handleOnClick=""
            className="rounded-3"
            text={<GoBell className="textDarkBlue fs-5" />}
          /> */}
        </div>
        {settingPopup && (
          <div
            ref={popupRef}
            className="position-absolute z-3 end-100 translate-bottom-y badge rounded-4 px-0 bg-white orangeBorder boxShadowDark d-flex flex-column fs-6 p-2"
          >
            <OutlineBtn
              handleOnClick={() => setChangePasswordPopup(!ChangePasswordPopup)}
              className="rounded-1 border-0 shadow-0 py-1 px-2 mx-2 my-1 "
              style={{ boxShadow: "none" }}
              text={
                <p className="small">
                  <LuKeyRound className="fs-6 me-2 px-" />
                  Change Password
                </p>
              }
            />

            <OutlineBtn
              handleOnClick={handleLogoutClick}
              className="rounded-1 border-0 shadow-0 py-1 px-2 mx-2 my-1 text-start"
              style={{ boxShadow: "none" }}
              text={
                <p className="small">
                  <IoLogOut className="fs-6 me-2" />
                  Logout
                </p>
              }
            />
          </div>
        )}

        {ChangePasswordPopup && (
          <ModalPopUp
            isOpen={"show"}
            onClose={() => setChangePasswordPopup(false)}
            modalTitle={"Change Password"}
            modalBody={
              <NewPasswordForm
                textOrange={"textOrange"}
                setChangePasswordPopup={setChangePasswordPopup}
              />
            }
            FooterButton={<></>}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
