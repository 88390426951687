import Styles from "./style.module.scss";
import LeftNav from "../../../Shared/LeftNav";
import { useLocation } from "react-router-dom";
import RightBar from "../../../Shared/RightBar";
import { Col, Container, Row } from "reactstrap";
import ProfileCard from "../../../Shared/ProfileCard";
import { useSelector } from "react-redux";

const GeneralLayout = (props) => {
  const location = useLocation();
  const excludedPaths = [
    "/",
    "/guest",
    "/signIn",
    "/contactUs",
    "/privacyPolicy",
    "/createAccount",
    "/changePassword",
    "/forgotPassword",
    "/verifyOTP/forgotPassword",
  ];

  const shouldRenderComponents = !excludedPaths.includes(location.pathname);
  const { user } = useSelector((state) => state.user);
  return (
    <>
      <Container fluid className=" overflow-hidden">
        <Row className="h-100 d-md-flex px-0">
          {shouldRenderComponents && user && (
            <Col md={1} className="px-0">
              <div className={`d-md-block ${Styles.leftSideNav}`}>
                <LeftNav />
              </div>
              <div
                className={`d-md-none d-block sticky-top ${Styles.ProfileClass}`}
              >
                <ProfileCard />
              </div>
            </Col>
          )}
          <Col
            className="p-0"
            lg={shouldRenderComponents && user ? 8 : 12}
            md={shouldRenderComponents && user ? 11 : 12}
          >
            <div className={`${Styles.childrenWidth} pb-md-2`}>
              <div className="d-lg-none d-md-block d-none">
                <ProfileCard />
              </div>
              {props.children}
            </div>
          </Col>
          {shouldRenderComponents && user && (
            <Col md={3} className="px-0 d-lg-block d-none">
              <div className={`${Styles.rightSideBar}`}>
                <RightBar />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default GeneralLayout;
