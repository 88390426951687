import React from "react";
import { Spinner } from "reactstrap";
import styles from "./style.module.scss";
import loadingGif from "../../Assets/Images/loadingGif/loading-loader.gif";

const LoadingScreen = (props) => (
  <div className={styles.loadingOverlay}>
    <div className={styles.loadingContent}>
      <img width={150} src={loadingGif} className={`img-fluid ${styles.rounded}`} alt="" />
      {props?.text && <p className="textYellow">{props?.text}</p>}
    </div>
  </div>
);

export default LoadingScreen;