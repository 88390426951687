import { number } from "yup";

export const SIGNIN_INITIAL_VALUES = {
  email: "",
  password: "",
  term_and_condition: false,
};
export const CREATE_MEETING_INITIAL_VALUES = {
  title: "",
};
export const GUEST_INITIAL_VALUES = {
  GuestName: "",
  GuestEmail: "",
  GuestPhone: "",
  howDidYouHear: "",
};

export const SIGNUP_INITIAL_VALUES = {
  dob: "",
  zip: "",
  city: "",
  email: "",
  state: "",
  gender: "",
  remarks: "",
  password: "",
  last_name: "",
  first_name: "",
  socialLogin: "",
  confirm_password: "",
  term_and_condition: false,
};

export const CHANGE_PASSWORD_INITIAL_VALUES = {
  password: "",
  confirm_password: "",
  previous_password: "",
};

export const FORGOT_PASSWORD_INITIAL_VALUES = {
  email: "",
};

export const PASSWORD_INITIAL_VALUES = {
  password: "",
  confirm_password: "",
};
