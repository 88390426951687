import { createSlice } from "@reduxjs/toolkit";
import { forgotPassword, newPassword } from "./forgotPasswordApi";

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    email: "",
    error: null,
    success: null,
    loading: "idle",
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
        state.email = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.error;
      })

      .addCase(newPassword.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(newPassword.fulfilled, (state, action) => {
        state.email = "";
        state.success = true;
        state.loading = "succeeded";
        state.error = null;
      })
      .addCase(newPassword.rejected, (state, action) => {
        state.loading = "failed";
        state.success = false;
        state.error = action.payload?.message || "Failed to update password";
      });
  },
});

export default forgotPasswordSlice.reducer;
