import {
  getMeeting,
  createMeeting,
  getAgoraToken,
  updateMeetingTime,
  getThisThatAnswers,
  getMostRecentTibit,
  postThisThatAnswers,
  updateMeetingStatus,
  updateMeetingEndTime,
} from "./meetingApi";
import { createSlice } from "@reduxjs/toolkit";

export const createMeetingSlice = createSlice({
  name: "Meetings",
  initialState: {
    email: "",
    meetings: [],
    error: null,
    success: null,
    loading: "idle",
    answers: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createMeeting.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(createMeeting.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(createMeeting.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.error;
      })
      .addCase(getMeeting.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMeeting.fulfilled, (state, action) => {
        state.loading = false;
        state.meetings = action.payload;
      })
      .addCase(getMeeting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getMostRecentTibit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMostRecentTibit.fulfilled, (state, action) => {
        state.loading = false;
        state.meetings = action.payload;
      })
      .addCase(getMostRecentTibit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateMeetingTime.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateMeetingTime.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(updateMeetingTime.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload?.error;
      })
      .addCase(updateMeetingEndTime.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateMeetingEndTime.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(updateMeetingEndTime.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload?.error;
      })
      .addCase(updateMeetingStatus.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateMeetingStatus.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(updateMeetingStatus.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload?.error;
      })
      .addCase(getAgoraToken.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAgoraToken.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(getAgoraToken.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload?.error;
      })
      .addCase(postThisThatAnswers.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(postThisThatAnswers.fulfilled, (state, action) => {
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(postThisThatAnswers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload?.error;
      })
      .addCase(getThisThatAnswers.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getThisThatAnswers.fulfilled, (state, action) => {
        state.answers = { ...action.payload.data };
        state.success = true;
        state.loading = "succeeded";
      })
      .addCase(getThisThatAnswers.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action?.payload?.error;
      });
  },
});

export default createMeetingSlice.reducer;
